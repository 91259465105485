/* eslint-disable max-len */
import * as React from 'react'
import './App.css'
import Main from './Main'
import {
  title,
  bullets,
  footer,
  headerText,
  rev,
  USPracticeTests
} from './data'
import SectionButton from './SectionButton'

const cursive = {
  fontFamily: 'cursive'
}

const App = () => {
  let i = 0

  const [currentChapter, setCurrentChapter] = React.useState(() => 1)

  const handleChapterChange = (newPage) => {
    console.log('handleChapterChange', newPage)
    newPage > 0 && newPage < USPracticeTests.length + 1 && setCurrentChapter(newPage)
  }
  let sectionId = 0
  const usTest = USPracticeTests[currentChapter - 1]
  return (
    <div className="component" style={{ maxWidth: 1200 }}>
      <div className="shadow align-content-start">rev {rev}</div>
      <br />
      <div className="shadow align-content-start">
        <h2><span style={cursive}>{title}</span></h2>
      </div>

      {/* <main>
        <Router>
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
              <Link className="nav-brand" to='/'>Home</Link>
              <Link className="nav-brand" to='/americanGovernment'>AmericanGovernment</Link>
              <Link className="nav-brand" to='/americanHistory'>AmericanHistory</Link>
              <Link className="nav-brand" to='/civics'>Civics</Link>
            </div>
          </nav>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/americanGovernment' exact component={AmericanGovernment} />
            <Route path='/americanHistory' exact component={AmericanHistory} />
            <Route path='/civics' exact component={Civics} />
          </Switch>
        </Router>
      </main > */}

      <div className="shadow-lg border border-dark rounded p-1 m-2">
        <ul>
          {USPracticeTests.map((e) => (
            <SectionButton key={++i} fontSize='18' text={e.title} id={++sectionId} currentId={currentChapter} handleIdChange={handleChapterChange} />
            // <SectionButton text={e.name + ' ' + e.title} currentIdhandleIdChange=handleSectionChange  sectionId)} />
          ))}
        </ul>

        <Main usTest={usTest} />
      </div>

      <div className="shadow align-content-start m-2">
        <div className="shadow align-content-start">
          <div style={cursive} >{headerText.map((e) => (<p key={++i}>{e}</p>))}</div>
          <h4><span style={cursive}>{footer}</span></h4>
        </div>

        {bullets.map((e) => (<li key={++i} >{e}</li>))}
      </div>
    </div>
  )
}

export default App
