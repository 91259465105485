/* eslint-disable react/prop-types */
import * as React from 'react'

const fontSize = 12

const Paging = ({
  currentPageIndex,
  totalPages,
  handlePageChange
}) => {
  const currentPage = currentPageIndex + 1
  const nextPage = currentPage + 1
  const previousPage = currentPage - 1

  const hasNext = totalPages && currentPage < totalPages
  const hasPrevious = totalPages && currentPageIndex > 0

  const handlePagingClick = (newPage) => {
    console.log('handlePagingClick', newPage)
    const newPageIndex = newPage - 1
    newPageIndex >= 0 && newPageIndex < totalPages && handlePageChange(newPageIndex)
  }

  console.log({
    currentPageIndex,
    totalPages,
    hasNext,
    hasPrevious,
    nextPage,
    previousPage
  })

  return (
    <div className="container m-2">
      <nav aria-label={`${currentPage} of ${totalPages}`}>
        <ul className="pagination pagination-sm">
          {hasPrevious && (
            <li className="page-item m-2">
              <button onClick={() => handlePagingClick(previousPage)}>
                <span style={{ fontSize }}>&laquo; Previous</span>
              </button>
            </li>
          )}
          <li className="page-item m-2" style={{ fontSize: 16 }}>{currentPage} of {totalPages}</li>
          {hasNext && (
            <li className="page-item m-2">
              <button onClick={() => handlePagingClick(nextPage)}>
                <span style={{ fontSize }}>Next &raquo;</span>
              </button>
            </li>)}
          {/* {hasPrevious && (
            <li className="page-item m-2">
              <a
                onClick={() => handlePagingClick(previousPage)}
                className="page-link"
                href="#">&laquo; Previous</a>
            </li>)}
          <li className="page-item m-2">{currentPage} of {totalPages}</li>
          {hasNext && (
            <li className="page-item m-2">
              <a
                onClick={() => handlePagingClick(nextPage)}
                className="page-link"
                href="#">Next &raquo;</a>
            </li>)} */}
        </ul>
      </nav>
    </div>
  )
}

export default Paging
