/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import * as React from 'react'
import SpeechFromQuestion from './SpeechFromQuestion'

const TestQuestionComponent = (props) => {
  const { testQuestion: { answers, question, questionNumber }, pageNumber } = props

  let i = 0
  return (
    <div className="component m-2">
      <span style={{ float: 'right' }}><SpeechFromQuestion words={question} /></span>
      <h2>
        <span>
          {pageNumber}. {question}
        </span>
      </h2>
      <div className="shadow-lg border border-dark rounded p-1 m-2">
        <span style={{ float: 'right' }}><SpeechFromQuestion words={answers.join(', ')} /></span>
        <ul>
          {answers.map((e) => (
            <li key={++i}>{e}</li>
          ))}
        </ul>
      </div>
      <div style={{ fontSize: 8, textAlign: 'right' }}>
        <small>Question id: {questionNumber}</small>
      </div>
    </div>
  )
}

export default TestQuestionComponent
