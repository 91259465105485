/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import * as React from 'react'
import TestSectionComponent from './TestSectionComponent'
import SectionButton from './SectionButton'

const Main = (props) => {
  const { title, sections } = props.usTest
  const [currentSection, setCurrentSection] = React.useState(() => 1)

  const handleSectionChange = (newPage) => {
    console.log('handleSectionChange', newPage)
    newPage > 0 && newPage < sections.length + 1 && setCurrentSection(newPage)
  }

  let sectionId = 0
  let i= 0
  return (
    <div className="component">
      <div className="shadow align-content-start">
        <h4><span style={{
          fontFamily: 'cursive'
        }}>
          {title}
        </span>
        </h4>
      </div>
      <div className="shadow-lg border border-dark rounded p-1 m-2">
        <ul>
          {sections.map((e) => (
            <SectionButton key={++i} fontSize='12' text={e.name + ' ' + e.title} id={++sectionId} currentId={currentSection} handleIdChange={handleSectionChange} />
            // <SectionButton text={e.name + ' ' + e.title} currentIdhandleIdChange=handleSectionChange  sectionId)} />
          ))}
        </ul>

        <TestSectionComponent testSection={sections[currentSection - 1]} />
      </div>
    </div>
  )
}

export default Main
