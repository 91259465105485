/* eslint-disable react/prop-types */
import React from 'react'
import { useSpeechSynthesis } from 'react-speech-kit'

const SpeechFromQuestion = (props) => {
  const { words } = props
  const { speak } = useSpeechSynthesis()

  return (
    <button onClick={() => speak({ text: words, rate: .75 })}>
      {'\u23F5'}
    </button>
  )
}
export default SpeechFromQuestion
