/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import * as React from 'react'
import Paging from './Paging'
import TestQuestionComponent from './TestQuestionComponent'


const TestSectionComponent = (props) => {
  const { title, name, questions } = props.testSection

  const [currentPageIndex, setCurrentPageIndex] = React.useState(() => 0)
  const handlePageChange = (newPageIndex) => {
    setCurrentPageIndex(newPageIndex)
  }
  if (currentPageIndex >= questions.length) {
    return handlePageChange(1)
  }

  const question = questions[currentPageIndex]

  return (
    <div className="component">
      <div className="shadow align-content-start">
        <h6><span style={{
          fontFamily: 'cursive'
        }}>
          {name}. {title}
        </span>
        </h6>
      </div>
      <div className="shadow-lg border border-dark rounded p-1 m-2">
        <TestQuestionComponent testQuestion={question} pageNumber={currentPageIndex + 1} />
      </div>
      <div style={{ minWidth: 300, textAlign: 'center', marginLeft: 50 }}>
        <Paging currentPageIndex={currentPageIndex} totalPages={questions.length} handlePageChange={handlePageChange} />
      </div>
    </div>
  )
}

export default TestSectionComponent
