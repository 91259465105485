/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import * as React from 'react'

const classNameButtonDefault = 'btn btn-outline-dark m-2'
const classNameButtonSelected = 'btn btn-dark m-2'

const SectionButton = (props) => {
  const { fontSize, text, id, currentId, handleIdChange } = props

  return (
    <button onClick={() => handleIdChange(id)} className={id === currentId ? classNameButtonSelected : classNameButtonDefault}>
      <span style={{ fontFamily: 'cursive', fontSize }}>{text}</span>
    </button>
  )
}

export default SectionButton
